






















import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import Select from "./Select.vue";

@Component({
  components: {
    Select,
  },
})
export default class SelectAccount extends Vue {
  @Prop({ required: true })
  value!: LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<AccountingAccountResponseDto>[] = [];
  loading = false;
  pagination = {
    page: FIRST_PAGE,
    search: "",
  };
  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams();
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findList, toOptions } = useCoa();
    params.sorts = "code:asc";
    this.loading = true;
    findList(params)
      .then(res => {
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOptions(res.data)];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: LabelInValue | undefined): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useCoa();
    this.resetState();
    this.pagination.search = filterBy({
      code: val,
      description: val,
    });

    this.fetchOptions(this.buildParams());
  }

  findOption(
    value: LabelInValue | undefined
  ): Option<AccountingAccountResponseDto> | undefined {
    if (!value || !value.key) return;
    return this.options.find(e => value.key === e.value);
  }

  buildParams(): RequestQueryParams {
    const params = new RequestQueryParams();
    const { page, search } = this.pagination;
    params.page = page - ONE;
    params.search = search;
    return params;
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }

  resetState(): void {
    this.pagination.page = ONE;
    this.options = [];
    this.allLoaded = false;
  }
}




















































































































































































import SelectProductCategory from "@/components/custom/select/SelectProductCategory.vue";
import SelectProductType from "@/components/custom/select/SelectProductType.vue";
import SelectProductUom from "@/components/custom/select/SelectProductUom.vue";
import SelectRack from "@/components/custom/select/SelectRack.vue";
import { ImageUploader } from "@/components/ImageUploader";
import { SelectTaxVatIn, SelectTaxVatOut } from "@/components/Tax";
import { Option } from "@/models/class/option.class";
import { ProductTypeEnum } from "@/models/enums/ProductType.enum";
import { WarehouseLocationResponseDto } from "@/models/interface/location";
import { ProductUomResponseDto } from "@/models/interface/master-uom";
import { ProductCategoryResponseDto } from "@/models/interface/product-category";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { FormValue, State as ProductState } from "@/store/product.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";

type Field = keyof FormValue;

@Component({
  components: {
    SelectProductCategory,
    SelectProductType,
    SelectProductUom,
    SelectTaxVatOut,
    SelectTaxVatIn,
    ImageUploader,
    SelectRack,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.productStore as ProductState,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      isTypeStockable: "productStore/isTypeStockable",
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "productStore/setForm",
    }),
  },
})
export default class FormBasicInfo extends Vue {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  @Ref("form")
  form!: FormModel;

  store!: ProductState;
  setForm!: (payload: Partial<ProductState["form"]>) => void;
  isTypeStockable!: () => boolean;

  formRules = {
    categoryName: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    type: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    description: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    name: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    code: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    merk: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    baseUnit: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    "productAccount.salesTax": [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    "productAccount.purchaseTax": [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    minimumStock: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    locationReceiveName: [
      {
        required: false,
        message: () => this.$t("lbl_validation_required_error").toString(),
      },
    ],
  };

  allowEditImage = false;

  updateForm(e: string | boolean | undefined, field: Field): void {
    this.setForm({
      [field]: e,
    });
  }

  /**
   * autofill field dari product category
   * - cost of sales account
   * - inventory account
   * - expense purchase account
   * - purchase return account
   * - purchase discount account
   * - asset cost account
   * - asset clearing account
   * - asset depreciation account
   * - asset accumulation account
   * - unbilled account
   * - sales account
   * - sales return account
   * - sales discount account
   */
  onChangeProductCategory(
    option: Option<ProductCategoryResponseDto> | undefined
  ): void {
    const meta: ProductCategoryResponseDto | undefined = option?.meta;
    this.setForm({
      ...this.store.form,
      productCategoryId: meta?.id || "",
      productAccount: {
        ...this.store.form.productAccount,
        costOfSalesAccount: {
          key: meta?.costOfSalesAccountId ?? "",
          label: meta?.costOfSalesAccountName ?? "",
        },
        inventoryAccount: {
          key: meta?.inventoryAccountId ?? "",
          label: meta?.inventoryAccountName ?? "",
        },
        expensePurchaseAccount: {
          key: meta?.expensePurchaseAccountId ?? "",
          label: meta?.expensePurchaseAccountName ?? "",
        },
        purchaseReturnAccount: {
          key: meta?.purchaseReturnAccountId ?? "",
          label: meta?.purchaseReturnAccountName ?? "",
        },
        purchaseDiscountAccount: {
          key: meta?.purchaseDiscountAccountId ?? "",
          label: meta?.purchaseDiscountAccountName ?? "",
        },
        unbilledAccount: {
          key: meta?.unbilledAccountId ?? "",
          label: meta?.unbilledAccountName ?? "",
        },
        salesAccount: {
          key: meta?.salesAccountId ?? "",
          label: meta?.salesAccountName ?? "",
        },
        salesReturnAccount: {
          key: meta?.salesReturnAccountId ?? "",
          label: meta?.salesReturnAccountName ?? "",
        },
        salesDiscountAccount: {
          key: meta?.salesDiscountAccountId ?? "",
          label: meta?.salesDiscountAccountName ?? "",
        },
        assetAccumulationAccount: {
          key: meta?.assetAccumulationAccountId ?? "",
          label: meta?.assetAccumulationAccountName ?? "",
        },
        assetClearingAccount: {
          key: meta?.assetClearingAccountId ?? "",
          label: meta?.assetClearingAccountName ?? "",
        },
        assetCostAccount: {
          key: meta?.assetCostAccountId ?? "",
          label: meta?.assetCostAccountName ?? "",
        },
        assetDepreciationAccount: {
          key: meta?.assetDepreciationAccountId ?? "",
          label: meta?.assetDepreciationAccountName ?? "",
        },
      },
    });
  }

  /**
   * product type STOCKABLE
   * - TRACK AS INVENTORY autofill true
   */
  onChangeProductType(e: string | undefined): void {
    if (e && e.toUpperCase() === ProductTypeEnum.STOCKABLE.toUpperCase()) {
      this.setForm({ trackAsInventory: true });
    }
  }

  onChangeUom(option: Option<ProductUomResponseDto> | undefined): void {
    this.setForm({
      baseUnit: option?.meta?.unit || "",
    });
  }

  onUploadSuccess(dto: FileCreateResponseDto | null): void {
    this.updateForm(dto?.url || "", "image");
  }

  editImage(): void {
    this.setForm({ image: "" });
    this.allowEditImage = true;
  }

  onChangeRack(e: Option<WarehouseLocationResponseDto> | undefined): void {
    this.setForm({
      locationReceiveId: e?.meta?.id || "",
      locationReceiveName: e?.label || "",
    });
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_account_setup")) + " ")]),
      _c(
        "a-form-model",
        {
          ref: "formAccount",
          attrs: {
            model: _vm.store.form,
            rules: _vm.rules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 10 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_cost_of_sale_account"),
                        prop: "costOfSalesAccount"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount.costOfSalesAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "costOfSalesAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.costOfSalesAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_inventory_account"),
                        prop: "inventoryAccount"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value: _vm.store.form.productAccount.inventoryAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "inventoryAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.inventoryAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_expense_purchase_account"),
                        prop: "expensePurchaseAccount"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount
                              .expensePurchaseAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "expensePurchaseAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.expensePurchaseAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_purchase_return_account"),
                        prop: "purchaseReturnAccount"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount.purchaseReturnAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "purchaseReturnAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.purchaseReturnAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_purchase_discount_account"),
                        prop: "purchaseDiscountAccount"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount
                              .purchaseDiscountAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "purchaseDiscountAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.purchaseDiscountAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unbilled_account"),
                        prop: "unbilledAccount"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value: _vm.store.form.productAccount.unbilledAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "unbilledAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.unbilledAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_sales_account"),
                        prop: "salesAccount"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value: _vm.store.form.productAccount.salesAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "salesAccount",
                              $$v
                            )
                          },
                          expression: "store.form.productAccount.salesAccount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_sales_return_account"),
                        prop: "salesReturnAccount",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount.salesReturnAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "salesReturnAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.salesReturnAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_sales_discount_account"),
                        prop: "salesDiscountAccount",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount.salesDiscountAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "salesDiscountAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.salesDiscountAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_asset_cost_account"),
                        prop: "assetCostAccount",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value: _vm.store.form.productAccount.assetCostAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "assetCostAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.assetCostAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_asset_clearing_account"),
                        prop: "assetClearingAccount",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount.assetClearingAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "assetClearingAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.assetClearingAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_asset_depreciation_account"),
                        prop: "assetDepreciationAccount",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount
                              .assetDepreciationAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "assetDepreciationAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.assetDepreciationAccount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_asset_accumulation_account"),
                        prop: "assetAccumulationAccountName",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectAccount", {
                        model: {
                          value:
                            _vm.store.form.productAccount
                              .assetAccumulationAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form.productAccount,
                              "assetAccumulationAccount",
                              $$v
                            )
                          },
                          expression:
                            "store.form.productAccount.assetAccumulationAccount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
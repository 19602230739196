
















































import SelectSupplier from "@/components/custom/select/SelectSupplier.vue";
import { Option } from "@/models/class/option.class";
import {
  AddressDataDto,
  ListContactDataDTO,
} from "@/models/interface/ContactDataDto.interface";
import {
  ProductSupplierRow,
  State as ProductState,
} from "@/store/product.store";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import SelectShipAddress from "./SelectShipAddress.vue";

@Component({
  components: {
    SelectSupplier,
    SelectShipAddress,
  },
  computed: {
    ...mapGetters({
      form: "productStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "productStore/addProductSupplier",
      deleteRow: "productStore/deleteProductSupplier",
    }),
  },
})
export default class FormProductSupplier extends Vue {
  form!: ProductState["form"];
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];

  columns = [
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "name",
      scopedSlots: { customRender: "supplier" },
    },
    {
      title: this.$t("lbl_supplier_code"),
      dataIndex: "id",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_address"),
      dataIndex: "address",
      scopedSlots: { customRender: "address" },
    },
  ];

  onChangeSupplier(
    row: ProductSupplierRow,
    opt: Option<ListContactDataDTO> | undefined
  ): void {
    row.id = opt?.meta?.supplierNumber || "";
    row.name = opt?.label || "";
    row.address = "";
    row.cityDistrict = "";
    row.partNumber = "";

    if (
      opt &&
      opt.meta &&
      opt.meta.addressDataList &&
      opt.meta.addressDataList.length > 0
    ) {
      const address: AddressDataDto | undefined = opt.meta.addressDataList.find(
        item => item.primaryShipTo && item.shipTo
      );
      row.address = address?.address || "";
      row.cityDistrict = address?.cityDistrict || "";
    }
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  handleDeleteRow(): void {
    this.deleteRow(this.selectedRowKeys);
    this.selectedRowKeys = [];
  }
}





































































































































import SelectAccount from "@/components/custom/select/SelectAccount.vue";
import { FormValue, State as ProductState } from "@/store/product.store";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";

@Component({
  components: {
    SelectAccount,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.productStore as ProductState,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "productStore/setForm",
    }),
  },
})
export default class FormProductAccount extends Vue {
  store!: ProductState;
  setForm!: (payload: Partial<FormValue>) => void;

  @Ref("formAccount")
  formAccount!: FormModel;

  rules = {
    costOfSalesAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    inventoryAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    expensePurchaseAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    purchaseReturnAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    purchaseDiscountAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    unbilledAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    salesAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    salesReturnAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
    salesDiscountAccountName: [
      {
        required: true,
        message: (): string =>
          this.$t("lbl_validation_required_error").toString(),
      },
    ],
  };
}
